/* eslint-disable no-tabs */
import React from 'react'
import config from 'SRC/config/config.yaml'

const OglasiTermsOfService = () => (
  <div className='novosti-text'>
    <p><strong>Opšte odredbe</strong></p>
    <p>Ovim Uslovima korišćenja se uređuju prava i obaveze u vezi sa korišćenjem Oglasi.me servisa (u daljem tekstu: Servis). Servis predstavlja uslugu informacionog društva koju pruža privredno društvo WebLab d.o.o., Jovana Tomaševića 1, 85000 Bar, Crna Gora, PIB: 03007448 (u daljem tekstu: Kompanija).</p>
    <p>Ovi Uslovi korišćenja čine sastavni dio Servisa i predstavljaju odredbe ugovora koji se zaključuje između Kompanije i svakog pojedinog korisnika Servisa. Kompanija omogućava korišćenje Servisa fizičkim i pravnim licima, isključivo na način i pod uslovima opisanim u ovim Uslovima korišćenja, na način i pod uslovima pod kojima se pruža usluga informacionog društva.</p>
    <p>Pristupom i korišćenjem Servisa Kompanije, korisnici pristaju na Uslove korišćenja, te na taj način zaključuju ugovor po pristupu sa Kompanijom kao pružaocem usluge informacionog društva. Na svaki pristup sadržajima Servisa, primjenjuju se ovi Uslovi korišćenja.</p>
    <p>Poslovanje Kompanije putem ovog Servisa regulisano je Zakonom o elektronskoj trgovini, Zakonom o obligacionim odnosima, Zakonom o oglašavanju, Zakonom o autorskom i srodnim pravima, Zakonom o žigovima, Zakonom o zaštiti podataka o ličnosti, Zakonom o zaštiti potrošača, kao i drugim propisima pravnog sistema Crne Gore.</p>
    <p>Kompanija je posvećena očuvanju i primjeni prava o zaštiti podataka o ličnosti koja uživaju fizička lica, kao i autorskih prava, a u svemu prema pravilima informacione struke, dobrim poslovnim običajima i u skladu sa važećim propisima Crne Gore.</p>

    <p><strong>Integritet i opis servisa</strong></p>

    <p>Servis je namijenjen oglašavanju širokog spektra proizvoda i usluga kao što su: vozila, plovila, motocikli, bicikla, auto djelovi i oprema i druge kategorije. Na Servisu je takođe dostupan i sadržaj koji je namijenjen informisanju korisnika o temama iz različitih oblasti.</p>
    <p>Svako korišćenje Servisa koje nije u skladu sa Uslovima korišćenja smatraće se zloupotrebom usluga koje pruža Kompanija i kršenjem Uslova korišćenja.</p>
    <p>Kompanija prenosi elektronske poruke koje su joj predate od strane korisnika usluga informacionog društva, ali ni na koji način: ne inicira njihov prenos, ne vrši odabir podataka ili dokumenata koji se prenose, ne izuzima ili mijenja podatke u sadržaju poruka ili dokumenata, niti odabira primaoca prenosa.</p>
    <p>Servis omogućava korisnicima pristup sadržaju koji je nastao agregiranjem javno dostupnih podataka, kao i sadržaju postavljenom od strane korisnika, isključivo bez ikakve naplate.</p>
    <p>Kompanija zadržava pravo izmjene, ukidanja (bilo privremeno, bilo trajno) bilo kog elementa Servisa, usluga koje pruža, kao i sadržaja koji se na te elemente odnose, bez prethodnog odobrenja ili obavještenja, uz primjenu dobrih poslovnih običaja.</p>
    <p>Sve vremenske odrednice i rokovi prikazani kroz Servis, kao i vremenska zona i radni dani računaju se prema važećim propisima Crne Gore.</p>

    <p><strong>Autorsko pravo</strong></p>

    <p>Kompanija ima isključivo autorsko pravo i prava intelektualne svojine na Servisu, kao i na svim pojedinim elementima koji ga čine, kao što su: tekst, vizuelni i audio elementi, vizuelni identitet, podaci i baze podataka, programski kod i drugi elementi servisa, kojih je autor.</p>
    <p>Neovlašćeno korišćenje bilo kog dijela Servisa, ili Servisa u cjelini, bez izričite prethodne dozvole u pisanoj formi izdate od Kompanije kao nosioca isključivih autorskih prava, smatraće se povredom autorskih prava Kompanije i podložno je pokretanju svih postupaka u punoj zakonskoj mjeri.</p>
    <p>Servis može sadržati i elemente na kojima isključiva autorska, žigovna i druga prava intelektualne svojine imaju druga lica, kao što su sadržaji korisnika Servisa, sadržaj poslovnih partnera, oglašivača i slično. Druga lica imaju isključivu odgovornost za sadržaj na kojem su nosioci tih prava, bez obzira na to što se takav sadržaj nalazi na Servisu Kompanije.</p>

    <p><strong>Korisnici</strong></p>

    <p>Korisnicima usluga koje putem Servisa pruža Kompanija smatraju se kako posjetioci, tako i registrovani korisnici.</p>

    <p><strong>Posjetilac</strong></p>

    <p>Posjetilac je lice koje putem Interneta pristupi Servisu u smislu ovih Uslova korišćenja, bez prijave ili registracije na Servis. Posjetilac može da se upozna sa dostupnim sadržajem na Servisu, bez plaćanja bilo kakve naknade.</p>

    <p><strong>Registrovani korisnik</strong></p>

    <p>Registrovani korisnik je pravno ili fizičko lice koje se registrovalo na Servisu pod uslovima i na način iz ovih Uslova korišćenja. Registracija na Servis je besplatna i dostupna svim posjetiocima.</p>
    <p>Prilikom registracije je potrebno da fizičko lice popuni formular sa ličnim podacima. Ovi podaci se koriste u skladu sa Zakonom o zaštiti podataka o ličnosti kao i sa Obavještenjem o privatnosti koje je istaknuto na Servisu.</p>
    <p>Registracija na Servis za pravna lica i preduzetnike je dostupna svim uredno registrovanim privrednim subjektima. Prilikom registracije je potrebno da privredni subjekt popuni formular sa podacima o pravnom licu. Ovi podaci ostaju u evidenciji Kompanije i pravnih lica u kojima Kompanija ima većinsko vlasništvo i neće biti korišćeni u druge svrhe, osim svrhe ostvarenja prava i obaveza iz ovih Uslova korišćenja.</p>
    <p>Registrovani korisnici prodaju mogu oglasiti kao prodavci sopstvenih proizvoda ili trgovci koji se bave trgovinom različitim artiklima i uslugama.</p>
    <p>Postavljanjem sadržaja na Servis, registrovani korisnik bezuslovno i neopozivo ovlašćuje Kompaniju da sadržaj prenese neodređenom broju lica.</p>

    <p>Registracijom na Servisu korisnici dobijaju sljedeće mogućnosti:</p>

    <ul style={{ listStyleType: 'disc', padding: 16 }}>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>Mogućnost postavljanja oglasne poruke</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>Mogućnost deaktivacije profila</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>Mogućnost brisanja profila</li>
    </ul>

    <p>Servis omogućava registrovanom korisniku da, ukoliko smatra da neko zloupotrebljava podatke korisnika i kontaktira korisnika drugim povodom, obavijesti Kompaniju o tome putem adrese: info@oglasi.me.</p>

    <p><strong>Oglašavanje i oglasna poruka</strong></p>

    <p>Prenosilac oglasne poruke je Kompanija koja je pružalac usluge informacionog društva i pruža uslugu oglašavanja isključivo putem Interneta.</p>
    <p>Za sadržaj, tačnost i ispravnost, kao i validnost oglasne poruke odgovornost snosi isključivo lice koje je oglasnu poruku unijelo. Za sve pravne posledice koje nastanu unosom oglasne poruke, odgovara isključivo lice koje je oglasnu poruku na Servis unijelo.</p>
    <p>Kompanija zadržava pravo da ne objavi sadržaj kojim se krše odredbe zakona Crne Gore, a naročito Zakon o oglašavanju. Kompanija može u svakom trenutku da odbije postavljanje bilo koje oglasne poruke kojom se krše odredbe Zakona ili ovih Uslova korišćenja.</p>
    <p>Kompanija prenosom oglasnih poruka ne vrši posredovanje u prenosu vlasništva nad proizvodima ili uslugama. Zaključenje ugovora kojim se prenosi neko pravo na proizvodu ili usluzi je u nadležnosti lica koje takav pravni posao preduzimaju. Takođe, prenosom oglasnih poruka Kompanija ni na koji način ne određuje niti utiče na sadržaj eventualnog ugovora o prenosu prava na proizvodu ili usluzi koji zaključuju ugovorne strane samostalno.</p>

    <p><strong>Postavljanje oglasnih poruka - oglasa</strong></p>

    <p>Registrovani korisnici koji oglašavaju proizvod ili uslugu, oglas dostavljaju putem formulara za postavljanje oglasa na Servisu. Korisnik je dužan da prilikom postavke oglasne poruke pravilno popuni sva obavezna polja iz formulara za postavljanje oglasa.</p>
    <p>U slučaju da je prije objave oglasa potrebno dodatno usaglasiti tekst oglasa ili način plaćanja, odnosno izvršiti određenu uplatu, oglasi će u tom slučaju biti objavljeni odmah nakon usaglašavanja odnosno izvršenja uplate</p>
    <p>Lica koja eventualno imaju neizmirene novčane obaveze prema Kompaniji stiču pravo na objavljivanje oglasa nakon evidentiranja izmirenja tih obaveza.</p>
    <p>U slučaju da Korisnik Servisa tvrdi da proizvod ili usluga (koji se oglašavaju putem Servisa) zadovoljavaju određene uslove, Kompanija ima pravo da u elektronskom obliku prikupi potrebne dokaze, kako bi uvažila tvrdnju Korisnika Servisa. Ukoliko Korisnik Servisa nije u mogućnosti da svoju tvrdnju dokumentuje, Kompanija zadržava pravo da mu uskrati besplatno oglašavanje na ovoj ili svim platformama kojim Kompanija upravlja.</p>
    <p>Kompanija, u skladu sa sopstvenom poslovnom politikom, koja se podjednako primjenjuje na sve Korisnike, ograničava broj besplatnih oglasa koje svakom pojedinom Korisniku stoje na raspolaganju.</p>
    <p>Ukoliko se dogodi da Korisnik zakupi paket usluga Servisa (koji namjerava da koristi tokom dužeg vremenskog perioda), taj Korisnik je zaštićen od eventualnih promjena cijena oglasa za vrijeme trajanja ugovora samo ukoliko je to navedeno u potpisanom ugovoru usluge oglašavanja.</p>

    <p><strong>Ocjene i komentari</strong></p>

    <p>Korisnici mogu da postavljaju i objavljuju sopstveni sadržaj, komentare i ocjene.</p>
    <p>Korisnici imaju mogućnost postavljanja ocjena od 1 do 5 zvjezdica. Kompanija zadržava diskreciono pravo poništavanja dodijeljenih ocjena, kao i pravo da u svakoj pojedinačnoj situaciji ne poništi ocjenu.</p>
    <p>Uz dogovor sa Kompanijom posjetioci imaju mogućnost postavljanja sadržaja u vidu komentara u okviru rubrike Savjeti i vijesti. Registrovani korisnici imaju mogućnost postavljanja sadržaja u okviru Knjige utisaka, rubrike Savjeti i vijesti, kao i prilikom korišćenja funkcionalnosti “Ocijeni prodavca”.</p>

    <p><strong>Plaćanje cijene usluga i povrat sredstava</strong></p>

    <p>Kompanija određene usluge informacionog društva pruža uz naknade prema Cjenovniku. Naknade dospijevaju za plaćanje po dostavljanju odgovarajućeg obavještenja o plaćanju (računa, fakture) u navedenim rokovima.</p>
    <p>Cjenovnik Kompanije je sastavni dio ovih Uslova korišćenja. Kompanija zadržava pravo da izmijeni Cjenovnik u bilo koje vrijeme.</p>
    <p>Prilikom naručivanja oglasa, čije se plaćanje vrši platnim karticama, Korisniku koji nije dostavio svu potrebnu dokumentaciju za objavu oglasa, ili isti nije u skladu sa Uslovima korišćenja, a uplatu za isti je izvršio putem platne kartice, Kompanija će inicirati povrat rezervisanih sredstava kod banke, ali ne može da garantuje za rok povrata ovih sredstava obzirom da je isti u nadležnosti banke vlasnika platne kartice kojom je plaćanje izvršeno. Sigurnost podataka prilikom kupovine garantuje procesor platnih kartica, pa se tako kompletni proces naplate obavlja na stranicama banke.</p>
    <p>Ukoliko nije došlo do saradnje između Kompanije i Korisnika, a sredstva sa kartice su povučena, Kompanija će inicirati povrat naplaćenih sredstava kod svoje banke ali ne može da utiče na rok povrata koji zavisi isključivo od banke vlasnika kartice kojom je oglas plaćen.</p>
    <p>U slučajevima kada je potrebno izvršiti povrat uplaćenih sredstava Korisniku Servisa koji je fizičko lice, neophodno je da Korisnik u navedenu svrhu dostavi Kompaniji broj lične karte.</p>

    <p><strong>Obavještenje</strong></p>

    <p>Korisnik se obavještava da mu Kompanija može periodično slati obavještenja koja se odnose na sadržaj Servisa, obavještenja koja se tiču funkcionisanja Servisa, novosti Kompanije, kao i ostala obavještenja i marketing promocije ove vrste putem uobičajenih kanala komunikacije poput, ali ne ograničavajući se na: viber poruke, SMS poruke, e-mail poruke i slično.</p>

    <p><strong>Smjernice</strong></p>

    <p>Registrovani korisnici se obavezuju da prilikom izrade sadržaja poštuju ovdje navedene Smjernice.</p>
    <p>Kompanija ima pravo, ali ne i obavezu, da bez obavještenja ukloni ili ne objavi sadržaj koji bilo koji registrovani korisnik postavi na Servis, a naročito ako taj sadržaj, prema diskrecionoj ocjeni Kompanije, uključuje (ali nije ograničen na):</p>

    <ul style={{ listStyleType: 'disc', padding: 16 }}>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>unošenje sadržaja u pogrešne rubrike (npr. navođenje broja telefona u polje koje nije predviđeno za to)</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>pogrešno kategorizovanje sadržaja (npr. postavljanje oglasa koji nisu relevantni za kategoriju u kojoj se oglašavaju)</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>ako se radi o duplom oglasu (identičan oglas objavljen više puta, oglašavanje istog proizvoda ili usluge samo pod izmijenjenim uslovima)</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>kada je predmet oglašavanja kupovina ili otkup</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>unošenjem neispravnih fotografija u oglas (npr. lošeg kvaliteta; sa naknadno dodatim kontakt telefonom, logotipom drugog sajta ili firme, tekstom prodato i sl.)</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>kada jedan oglas sadrži više predmeta ili usluga oglašavanja</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>otvoreno uvredljiv sadržaj, ili sadržaj koji promoviše rasizam, netrpeljivost, mržnju, ili fizičko povređivanje bilo koje vrste, a koje je usmjereno na bilo koju grupu, ili pojedinca</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>uznemirava, ili promoviše uznemiravanje druge osobe</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>eksploatiše ljude na seksualni, ili nasilni način</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>sadrži nagost, pretjerano nasilje, ili uvredljiv sadržaj, ili sadrži vezu do web-sajtova za odrasle</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>traži lične informacije od osoba mlađih od 18 godina</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>javno objavljuje informacije koje predstavljaju, ili stvaraju rizik po privatnost, ili bezbjednost bilo koje osobe</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>sadrži, ili promoviše informacije za koje znate da su netačne, ili koje navode na pogrešan zaključak, ili promovišu nedozvoljene aktivnosti, ili čiji je sadržaj uvredljiv, zastrašujući, pun prijetnji, nepristojan, ili klevetnički</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>sadrži, ili promoviše nedozvoljenu, ili neovlašćenu kopiju zaštićenog rada druge osobe</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>uključuje prenos neželjene pošte, cirkularnih pisama, ili masovne pošte, instant poruka, odnosno „spam“</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>sadrži stranice sa ograničenim pristupom, ili stranice kojima je moguće pristupiti samo uz pomoć lozinke, ili skrivene stranice, ili fotografije (one koje nisu povezane sa drugim stranicama)</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>podstiče, ili promoviše kriminalne aktivnosti, ili poslove, ili pruža uputstva za obavljanje nedozvoljenih aktivnosti, uključujući, ali ne ograničavajući na izradu, ili kupovinu ilegalnog oružja, kršenje nečije privatnosti, ili pronalaženje, ili kreiranje računarskih virusa</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>traži lozinke, ili informacije koje vas lično identifikuju u komercijalne, ili nedozvoljene svrhe od drugih korisnika</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>uključuje druge komercijalne aktivnosti i/ili prodaju van svrhe Servisa, bez prethodne pisane saglasnosti Kompanije kao što su takmičenja, lutrija sa nagradama u robi i uslugama, ili piramidalne šeme</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>uključuje fotografiju, ili video zapis druge osobe koju ste objavili bez pristanka te osobe, ili</li>
      <li style={{ display: 'list-item', listStyle: 'inherit' }}>krši prava na privatnost, prava na javno objavljivanje, pravo na zaštitu od klevete, autorska prava, pravo na žig, prava iz ugovora, ili neka druga lična prava.</li>
    </ul>

    <p><strong>Ograničenje od odgovornosti</strong></p>

    <p>Korisnici Servis koriste isključivo na svoju sopstvenu odgovornost. Korisnik izričito prihvata da Kompanija ne može biti odgovorna za ponašanje drugih korisnika ili trećih lica, kao i da rizik od moguće štete u cjelosti snose ta lica, a u skladu sa važećim zakonodavstvom Crne Gore.</p>
    <p>Tekstovi (komentari), ocjene i drugi sadržaj koje korisnici postavljaju u za to predviđenim rubrikama, moraju biti tačni i ispravni. Za tačnost na taj način unijetih podataka, odgovaraju korisnici koji su ih unijeli. Tačnost unosa podrazumijeva da dolaze iz nekog kompetentnog izvora, odnosno ličnog iskustva korisnika. Za svaki pojedini unos isključivu odgovornost snosi korisnik koji je unos izvršio.</p>
    <p>Kompanija ne garantuje za tačnost, pouzdanost, kao ni za sam sadržaj postavljen od strane korisnika. Kompanija ne inicira prenos elektronske poruke koju mu je predao korisnik usluga, ne vrši odabir podataka ili dokumenata koji se prenose, ne vrši izuzimanje ili izmjenu podataka u sadržaju poruke ili dokumenta i ne vrši odabir primaoca prenosa.</p>
    <p>Ova izjava o odgovornosti odnosi se na svu štetu (materijalnu i/ili nematerijalnu), ili povrede koje bi mogle nastati iz skrivenih nedostataka, grešaka, prekida, brisanja, kvara, kašnjenja u radu ili prenosu računarskih virusa, prekida u komunikacijama, krađe, uništenja ili neovlašćenog pristupa podacima, promjene ili zloupotrebe podataka od strane trećih lica, raskida ugovora, ponašanja suprotnog Uslovima korišćenja, nemara i dr.</p>
    <p>Kompanija nije odgovorna za eventualnu privremenu nedostupnost Servisa, niti za djelimično ili potpuno nefunkcionisanje ili pogrešno funkcionisanje istog. Kompanija nije odgovorna za tehničke probleme koji mogu dovesti do kašnjenja i/ili pogrešne obrade elektronskih podataka, uključujući i sistemski sat. Za prethodno navedeno odgovorni su davaoci Internet usluga.</p>
    <p>Kao pružalac usluge informacionog društva, Kompanija nije odgovorna za bilo koji sadržaj koji je postavilo drugo lice, korisnik, uključujući ali ne ograničavajući se na oglasnu poruku koju prenosi, jer niti inicira prenos, niti vrši odabir sadržaja koji se prenose, niti je izuzela ili izmijenila podatke u sadržaju koji se prenosi, niti je odabrala primaoca prenosa, odnosno primaoca sadržaja.</p>
    <p>Kompanija ne garantuje za ponašanje trećih lica, ni svojih korisnika. Kompanija, pored ostalog, ne pruža nikakve garancije da će Korisnik koji je oglasio proizvod ili uslugu biti kontaktiran povodom tog oglasa od strane trećih lica (odnosno da će zaključiti pravni posao koji je predmet oglasa), kao ni da će dostupne informacije sadržati tačne i istinite podatke.</p>
    <p>Servis može biti privremeno nedostupan ili dostupan u ograničenom obimu, kao rezultat redovnog ili vanrednog održavanja sistema, ili u slučaju unapređenja sistema.</p>

    <p><strong>Prodaja na daljinu</strong></p>

    <p>Kompanija ne snosi odgovornost za bilo kakve transakcije ili kupoprodajne ugovore koji su sklopljeni između korisnika putem Servisa. Sve transakcije se odvijaju isključivo između prodavca i kupca, a Kompanija ne preuzima nikakvu odgovornost za kvalitet, sigurnost, zakonitost, ili dostupnost proizvoda ili usluga koje se oglašavaju.</p>
    <p>Kompanija je odgovorna samo za artikle koji su prodati putem njenih brendova i prodavnica, kao što je AutoEkspert.me što je I definisano na stranici prava potrošača. Svi ostali artikli i usluge oglašeni putem Oglasi.me su odgovornost isključivo prodavca koji je postavio oglas.</p>

    <p><strong>Jurisdikcija i rešavanje sporova</strong></p>

    <p>Na sve što nije regulisano ovim Uslovima korišćenja primjenjuju se važeći propisi Crne Gore.</p>
    <p>Na sve sporove do kojih može doći između Kompanije i Korisnika u vezi sa korišćenjem Servisa primjenjuju se važeći propisi Crne Gore. Kompanija i Korisnik se obavezuju da spor pokušaju da riješe mirnim putem, a ukoliko u tome ne uspiju nadležan je sud u Baru.</p>

    <p><strong>Završne odredbe</strong></p>

    <p>Kompanija ima pravo da u svako doba izmijeni ili dopuni ove Uslove korišćenja, tako što će izmjene i dopune u prečišćenom tekstu objaviti na Internet prezentaciji Servisa.</p>
    <p>Ovi Uslovi korišćenja počinju da se primjenjuju 28.07.2024. godine.</p>
    <p>Svaki odštampani primjerak ovih Korisničkih uslova proizvodi puno pravno dejstvo na osnovu odredbi Zakona o elektronskom dokumentu, i ne može mu se osporiti punovažnost ili dokazna snaga.</p>

    <p><a href="/documents/prijava-distanciona-prodaja-weblab-ecommerce.pdf" target="_blank">Prijava webshopa</a></p>
    <p><a href="/documents/prijava-marketing-propaganda-promocija-weblab.pdf" target="_blank">Prijava usluge marketinga i propagande</a></p>

    <p>---</p>

    <p>Bar, Crna Gora 28/07/2024</p>
  </div>
)

export const TermsOfService = () => {
  switch (config.siteName) {
    case 'Oglasi': return <OglasiTermsOfService />
    default: return null
  }
}
